import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

const maxWidth = 1680;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        letter-spacing: -0.02em;
    }
    
    a {
        text-decoration: none;
    }

    a, p, div {
        color: black;
    }

    p a {
        border-bottom: 1px solid black;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const videoEmbed = css`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export const button = css`
    font-family: 'Cooper';
	font-size: 20px;

	padding: 0 20px;
	height: 30px;
	border: 3px solid black;
	border-radius: 12px;

	${hoverState}
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	${media.phone`
		font-size: 18px;
		height: 32px;
		border: 2px solid black;
	`}
`