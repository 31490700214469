import { combineReducers } from 'redux'
import settings from './settings'
import { default as cart } from './cart'

const reducer = combineReducers({
    settings,
    cart
})

export default reducer
