import { createGlobalStyle } from 'styled-components'

import RobotoLight from '../assets/fonts/RobotoMono-Light.ttf'
import RobotoLightItalic from '../assets/fonts/RobotoMono-LightItalic.ttf'
import Cooper from '../assets/fonts/cooperl.ttf'
import Zipper from '../assets/fonts/zipper-std.otf'

export const Fonts = createGlobalStyle`
    body {
        font-size: 14px;
        font-family: 'Courier', Courier, serif;
        -webkit-font-smoothing: antialiased;
    }

    @font-face {
        font-family: 'Robot';
        src: url(${RobotoLight});
    }

    @font-face {
        font-family: 'Robot';
        font-style: italic;
        src: url(${RobotoLightItalic});
    }

    @font-face {
        font-family: 'Cooper';
        src: url(${Cooper});
    }

    @font-face {
        font-family: 'Zipper';
        src: url(${Zipper});
    }
`
