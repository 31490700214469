import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store/configureStore'
import { GlobalStyles } from './src/styles/global';
import { Fonts } from './src/styles/fonts'
import { PortalProvider } from 'react-portal-hook'
export default ({ element, props  }) => {
    return (
        <Provider 
            store={store}
        >   
            <PortalProvider>
                <GlobalStyles/>
                <Fonts/>
                {element}
            </PortalProvider>
        </Provider>
    )
}